<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="/dashboard">
        <img
          class="navbar-brand-full"
          src="@/assets/img/new_icon_logo.png"
          width="125"
          height="25"
          alt="Treasury Logo"
        />
        <img
          class="navbar-brand-minimized"
          src="@/assets/img/icon_logo.png"
          width="30"
          height="30"
          alt="Treasury Logo"
        />
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" />
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right style="margin-left: -90px;">
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            <em>{{ $name() }} </em>
          </template>
          <b-dropdown-header id="dropdown-header-1">Settings</b-dropdown-header>
          <b-dropdown-item href="profile"
            ><i class="fa fa-home"></i> Profile</b-dropdown-item
          >
          <b-dropdown-item @click="logout"
            ><i class="fa fa-sign-out"></i> Sign Out</b-dropdown-item
          >
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <nav class="sidebar-nav">
          <VuePerfectScrollbar
            class="scroll-area"
            :settings="psSettings"
            @ps-scroll-y="scrollHandle"
          >
            <ul class="nav">
              <li class="nav-title">
                General
              </li>
              <li class="nav-item" @click="activeLink">
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Dashboard' }"
                >
                  <i class="nav-icon icon-speedometer"></i> Dashboard
                </router-link>
              </li>

              <!-- <li class="nav-item" @click="activeLink" v-if="$can('see-pricing')">
                <router-link class="nav-link" :class="{active: isActive}" :to="{name: 'Pricing Treasury'}">
                  <i class="nav-icon fa fa-dollar"></i> Pricing
                </router-link>
              </li> -->

              <li
                class="nav-item nav-dropdown"
                disabled
                v-if="$can('see-pricing')"
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon fa fa-dollar"></i> Pricing
                </div>
                <ul class="nav-dropdown-items">
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Gold Margin Control' }"
                    >
                      <i class="nav-icon fa fa-dollar"></i> Gold - Margin
                      Control
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Gold Threshold Control' }"
                    >
                      <i class="nav-icon fa fa-dollar"></i> Gold - Threshold
                      Control
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Currency Control' }"
                    >
                      <i class="nav-icon fa fa-dollar"></i> Currency Control
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Crypto Pricing Treasury' }"
                    >
                      <i class="nav-icon fa fa-percent"></i> Crypto Pricing
                    </router-link>
                  </li>
                </ul>
              </li>

              <li
                class="nav-item nav-dropdown"
                disabled
                v-if="$can('crypto_product')"
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon fa fa-dollar"></i> Crypto
                </div>
                <ul class="nav-dropdown-items">
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Crypto Product' }"
                    >
                      <i class="nav-icon fa fa-dollar"></i> Crypto Product
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                v-if="$can('list_voucher')"
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon fa fa-tag"></i> Voucher
                </div>
                <ul class="nav-dropdown-items">
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('list_voucher')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Voucher' }"
                    >
                      <i class="nav-icon fa fa-list"></i> List
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('list_voucher_partnership')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Voucher Partnership' }"
                    >
                      <i class="nav-icon fa fa-list"></i> List Marketplace
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('import_voucher')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Voucher Import' }"
                    >
                      <i class="nav-icon fa fa-list"></i> Import
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                v-if="$can('partner_voucher_list')"
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon fa fa-tag"></i> Voucher Partner
                </div>
                <ul class="nav-dropdown-items">
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('partner_voucher_list')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Voucher Partner List' }"
                    >
                      <i class="nav-icon fa fa-list"></i> List
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('partner_voucher_settlement_list')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Voucher Settlement' }"
                    >
                      <i class="nav-icon fa fa-list"></i> Settlement
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('partner_voucher_manage_balance_list')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Voucher Manage Balance' }"
                    >
                      <i class="nav-icon fa fa-list"></i> Manage Balance
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('partner_voucher_manage_junk_list')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Voucher Junk' }"
                    >
                      <i class="nav-icon fa fa-list"></i> Junk
                    </router-link>
                  </li>
                  <!-- <li class="nav-item" @click="activeLink" v-if="$can('partner_voucher_manage_balance_list')">
                    <router-link class="nav-link" :class="{active: isActive}" :to="{name: 'Voucher Junk'}">
                      <i class="nav-icon fa fa-list"></i> Junk
                    </router-link>
                  </li> -->
                </ul>
              </li>
              <li
                class="nav-item"
                @click="activeLink"
                v-if="$can('list_withdraw')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Jual Emas' }"
                >
                  <i class="nav-icon fa fa-upload"></i> Jual Emas
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="activeLink"
                v-if="$can('report_bappebti')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Daily Report BAPPEBTI' }"
                >
                  <i class="nav-icon fa fa-dollar"></i> Daily Report BAPPEBTI
                </router-link>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                v-if="$can('addons_transaction') || $can('addons_setup')"
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon fa fa-pie-chart"></i> Add Ons
                </div>
                <ul class="nav-dropdown-items">
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('addons_setup')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Add Ons Setup' }"
                    >
                      <i class="nav-icon fa fa-list"></i> Add Ons Setup
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('addons_transaction')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Transaction History Add Ons' }"
                    >
                      <i class="nav-icon fa fa-list"></i> Transaction History
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="nav-item"
                @click="activeLink"
                v-if="$can('partner_gold_sell_list')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Jual Emas Partner' }"
                >
                  <i class="nav-icon fa fa-upload"></i> Jual Emas Partner
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="activeLink"
                v-if="$can('list_withdraw')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Jual Emas Celengan' }"
                >
                  <i class="nav-icon fa fa-upload"></i> Jual Emas Celengan
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="activeLink"
                v-if="$can('list_withdraw')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Withdraw Celenganku' }"
                >
                  <i class="nav-icon fa fa-upload"></i> Withdraw Celenganku
                </router-link>
              </li>
              <li class="nav-item" @click="activeLink" v-if="$can('monitor')">
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Monitor Celengan' }"
                >
                  <i class="nav-icon fa fa-upload"></i> Monitor Celengan
                </router-link>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                id="transaction"
                v-if="
                  $can('list_gold_transactions' || 'list_partner_transaction')
                "
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon icon-credit-card"></i> Transaction History
                </div>
                <ul class="nav-dropdown-items">
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Partner Transaction' }"
                    >
                      <i class="nav-icon icon-list"></i> Partner Transaction
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Gold Transaction' }"
                    >
                      <i class="nav-icon icon-list"></i> Gold Transaction
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Voucher Transaction' }"
                    >
                      <i class="nav-icon icon-list"></i> Voucher Transaction
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Crypto Transaction History' }"
                    >
                      <i class="nav-icon icon-list"></i> Crypto Transaction
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Wallet Transaction History' }"
                    >
                      <i class="nav-icon icon-list"></i> Wallet Transaction
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Staking Transaction History' }"
                    >
                      <i class="nav-icon icon-list"></i>
                      Staking Emas Transaction
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('report_transaction')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Report Transaction History' }"
                    >
                      <i class="nav-icon icon-list"></i> Report Transaction
                      History
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                id="jamimas"
                v-if="
                  $can('list_jamimas_transaction' || 'list_jamimas_request')
                "
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon icon-list"></i>
                  Jaminan Emas
                </div>
                <ul class="nav-dropdown-items">
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Request Jaminan Emas' }"
                    >
                      <i class="nav-icon icon-list"></i> Jamimas Request
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Jaminan Emas Transaction' }"
                    >
                      <i class="nav-icon icon-list"></i> Jamimas Transaction
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                id="report"
                v-if="$can('dashboard_ich')"
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon icon-credit-card"></i> Data Transaction ICH
                </div>
                <ul class="nav-dropdown-items">
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Report Transaction ICH' }"
                    >
                      <i class="nav-icon icon-list"></i> Report Transaction ICH
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('finance_ich')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Filter Transaction' }"
                    >
                      <i class="nav-icon icon-list"></i> Filter Transaction
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                id="report"
                v-if="$can('promotion') || $can('promotion_code')"
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon icon-credit-card"></i> Promosi
                </div>
                <ul class="nav-dropdown-items">
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('promotion')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Promotion' }"
                    >
                      <i class="nav-icon icon-list"></i> List Promosi
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('promotion_code')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Promotion Codes' }"
                    >
                      <i class="nav-icon icon-list"></i> List Promo Code
                    </router-link>
                  </li>
                  <!-- <li class="nav-item" @click="activeLink">
                    <router-link class="nav-link" :class="{active: isActive}" :to="{name: 'Pop Up Beli Emas'}">
                      <i class="nav-icon icon-list"></i> Pop Up Beli Emas
                    </router-link>
                  </li> -->
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Pop Up' }"
                    >
                      <i class="nav-icon icon-list"></i> Pop Up
                    </router-link>
                  </li>
                  <!-- <li class="nav-item" @click="activeLink">
                    <router-link class="nav-link" :class="{active: isActive}" :to="{name: 'Pop Up Home Page'}">
                      <i class="nav-icon icon-list"></i> Pop Up Home Page
                    </router-link>
                  </li> -->
                </ul>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                id="army"
                v-if="
                  $can(
                    'list_point_transaction' ||
                      'list_user_army' ||
                      'list_release_point'
                  )
                "
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon icon user"></i> Treasury Army
                </div>
                <ul class="nav-dropdown-items">
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('list_point_transaction')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Point Transaction' }"
                    >
                      <i class="nav-icon icon-list"></i> Point Transaction
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('list_user_army')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'User Army' }"
                    >
                      <i class="nav-icon icon-list"></i> User Army
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('list_release_point')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Release Point' }"
                    >
                      <i class="nav-icon icon-list"></i> Release Point
                    </router-link>
                  </li>
                </ul>
              </li>
              <!-- <li class="nav-item" @click="activeLink" v-if="$can('list_article')">
                <router-link class="nav-link" :class="{active: isActive}" :to="{name: 'List Articles'}">
                  <i class="nav-icon icon-note"></i> Article
                </router-link>
              </li> -->
              <li
                class="nav-item"
                @click="activeLink"
                v-if="$can('list_banner')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Mobile Banner' }"
                >
                  <i class="nav-icon fa fa-image"></i> Mobile Banner
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="activeLink"
                v-if="$can('normalization_transaction')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Normalisasi Transaksi' }"
                >
                  <i class="nav-icon fa fa-image"></i> Normalisasi Transaksi
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="activeLink"
                v-if="$can('list_banner')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Splash Screen' }"
                >
                  <i class="nav-icon fa fa-image"></i> Splash Screen
                </router-link>
              </li>
              <li class="nav-item" @click="activeLink" v-if="$can('list_page')">
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Page' }"
                >
                  <i class="nav-icon icon-layers"></i> Pages
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="activeLink"
                v-if="$can('send_push_notification')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Push Notification' }"
                >
                  <i class="nav-icon fa fa-bullhorn"></i> Push Notification
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="activeLink"
                v-if="$can('price_alert')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Alert' }"
                >
                  <i class="nav-icon fa fa-bullhorn"></i> Push Alert
                </router-link>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                id="jewelry"
                v-if="
                  $can('list_jewelry') ||
                    $can('list_ecommerce_order') ||
                    $can('list_jewelry_order')
                "
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon fa fa-shopping-cart"></i> Jewelry
                </div>
                <ul class="nav-dropdown-items">
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('list_jewelry')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Jewelry List' }"
                    >
                      <i class="nav-icon icon-list"></i> Jewelry List
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('list_jewelry_category')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Jewelry Categories' }"
                    >
                      <i class="nav-icon icon-list"></i> Jewelry Category
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="
                      $can('list_ecommerce_order') || $can('list_jewelry_order')
                    "
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Jewelry Orders' }"
                    >
                      <i class="nav-icon icon-list"></i> Jewelry Order
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                id="jewelry"
                v-if="$can('background_share') || $can('quote')"
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon fa fa-image"></i> Rencana Emas
                </div>
                <ul class="nav-dropdown-items">
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('background_share')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Background image Share' }"
                    >
                      <i class="nav-icon icon-list"></i> Background Image Share
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink" v-if="$can('quote')">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Quotes' }"
                    >
                      <i class="nav-icon icon-list"></i> Quotes
                    </router-link>
                  </li>
                </ul>
              </li>
              <!-- <li class="nav-item" @click="activeLink" v-if="$can('list_ecommerce_order')">
                <router-link class="nav-link" :class="{active: isActive}" :to="{name: 'Shipping Cost RPX'}">
                  <i class="nav-icon fa fa-truck"></i> Shipping Cost
                </router-link>
              </li> -->
              <li
                class="nav-item nav-dropdown"
                disabled
                id="dinar"
                v-if="$can('list_ecommerce_order')"
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon fa fa-money"></i> Shipping Cost
                </div>
                <ul class="nav-dropdown-items">
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Shipping Cost RPX' }"
                    >
                      <i class="nav-icon fa fa-truck"></i> Shipping Cost RPX
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Shipping Cost SAP' }"
                    >
                      <i class="nav-icon fa fa-truck"></i> Shipping Cost SAP
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                id="reseller"
                v-if="$can('voucher_reseller')"
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon fa fa-money"></i> Voucher Reseller
                </div>
                <ul class="nav-dropdown-items">
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      v-if="$can('voucher_digital')"
                      :to="{ name: 'Voucher Digital Reseller' }"
                    >
                      <i class="nav-icon fa fa-truck"></i> Setting Voucher
                      Digital
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      v-if="$can('voucher_physical')"
                      :to="{ name: 'Voucher Fisik Reseller' }"
                    >
                      <i class="nav-icon fa fa-truck"></i> Setting Voucher Fisik
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      v-if="$can('voucher_reseller_transaction')"
                      :to="{ name: 'Transaksi Beli Voucher' }"
                    >
                      <i class="nav-icon fa fa-truck"></i> Transaksi Beli
                      Voucher
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      v-if="$can('user_reseller')"
                      :to="{ name: 'Voucher User Reseller' }"
                    >
                      <i class="nav-icon fa fa-truck"></i> User Reseller
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                id="dinar"
                v-if="$can('dinar') || $can('list_redeem')"
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon fa fa-money"></i> Cetak Emas
                </div>
                <ul class="nav-dropdown-items">
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('list_redeem')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'List Cetak Emas' }"
                    >
                      <i class="nav-icon fa fa-exchange"></i> Transaksi Cetak
                      Emas
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('dinar_products')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'List Koin Nusantara Product' }"
                    >
                      <i class="nav-icon icon-list"></i> Daftar Produk
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('dinar_transaction')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'List Koin Nusantara Order' }"
                    >
                      <i class="nav-icon icon-list"></i> Transaksi Koin
                      Nusantara
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('dinar_setting')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Koin Nusantara Settings' }"
                    >
                      <i class="nav-icon icon-list"></i> Pengaturan
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                id="hadiah-emas"
                v-if="$can('list_gift_card')"
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon fa fa-money"></i> Hadiah Emas
                </div>
                <ul class="nav-dropdown-items">
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'List Design' }"
                    >
                      <i class="nav-icon icon-list"></i> Desain List
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'List Transaction' }"
                    >
                      <i class="nav-icon icon-list"></i> Transaction
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                id="acl"
                v-if="$can('manage_acl')"
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon icon-user"></i> User & ACL
                </div>
                <ul class="nav-dropdown-items">
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('list_customer')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'End User' }"
                    >
                      <i class="nav-icon icon-list"></i> Total User
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('list_customer')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Agent' }"
                    >
                      <i class="nav-icon icon-list"></i> Total Agent
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('manage_permission')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Permissions' }"
                    >
                      <i class="nav-icon icon-list"></i> Permissions
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('manage_role')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Roles' }"
                    >
                      <i class="nav-icon icon-list"></i> Roles
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('list_teman_treasury')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Teman Treasury' }"
                    >
                      <i class="nav-icon icon-list"></i> Teman Treasury
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('list_history_teman_treasury')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'History Teman Treasury' }"
                    >
                      <i class="nav-icon icon-list"></i> History Teman Treasury
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('list_point_treasury')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Point Teman Treasury' }"
                    >
                      <i class="nav-icon icon-list"></i> Point Teman Treasury
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('manage_users')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Super Users' }"
                    >
                      <i class="nav-icon icon-list"></i> Super User
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink" v-if="$can('kyc')">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Verifikasi KYC' }"
                    >
                      <i class="nav-icon icon-list"></i> Verifikasi KYC
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink" v-if="$can('kyc')">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Verifikasi Partner' }"
                    >
                      <i class="nav-icon icon-list"></i> Verifikasi KYC Partner
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="nav-item"
                @click="activeLink"
                v-if="$can('cron_setting')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Cron Monitoring' }"
                >
                  <i class="nav-icon fa fa-desktop"></i> Cron Monitoring
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="activeLink"
                v-if="$can('ich_hash_trade_id')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'ICH Trade & Customer ID' }"
                >
                  <i class="nav-icon fa fa-cube"></i> ICH Trade & Customer ID
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="activeLink"
                v-if="$can('list_error_log')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'System Logs Error' }"
                >
                  <i class="nav-icon fa fa-warning"></i> System Error Logs
                </router-link>
              </li>
              <li class="nav-item" @click="activeLink" v-if="$can('activity')">
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Activity Log' }"
                >
                  <i class="nav-icon fa fa-warning"></i> Activity Logs
                </router-link>
              </li>
              <li class="nav-item" @click="activeLink" v-if="$can('revenue')">
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Revenue' }"
                >
                  <i class="nav-icon fa fa-money"></i> Revenue
                </router-link>
              </li>

              <li
                class="nav-item nav-dropdown"
                disabled
                id="analyst"
                v-if="$can('analyst')"
              >
                <div class="nav-link nav-dropdown-toggle" @click="handleClick">
                  <i class="nav-icon icon-credit-card"></i> Analyst
                </div>
                <ul class="nav-dropdown-items">
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Analyst' }"
                    >
                      <i class="nav-icon icon-credit-card"></i> Analyst
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Crypto Analyst' }"
                    >
                      <i class="nav-icon icon-credit-card"></i> Crypto Analyst
                    </router-link>
                  </li>
                  <li class="nav-item" @click="activeLink">
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Celenganku Analyst' }"
                    >
                      <i class="nav-icon icon-credit-card"></i> Celeganku
                      Analyst
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item" @click="activeLink" v-if="$can('tax')">
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Tax' }"
                >
                  <i class="nav-icon fa fa-money"></i> Tax
                </router-link>
              </li>
              <li
                class="nav-item"
                @click="activeLink"
                v-if="$can('sandbox_payment')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Sandbox' }"
                >
                  <i class="nav-icon fa fa-money"></i> SandBox
                </router-link>
              </li>
              <li class="nav-title" v-if="$can('usership')">
                Usership
              </li>
              <li class="nav-item" @click="activeLink" v-if="$can('usership')">
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Total User' }"
                >
                  <i class="nav-icon fa fa-child"></i> Total User
                </router-link>
              </li>
              <li
                class="nav-item nav-dropdown"
                disabled
                v-if="$can('usership')"
              >
                <div
                  class="nav-link nav-dropdown-toggle"
                  id="active"
                  @click="handleClick"
                >
                  <i class="nav-icon fa fa-user"></i> Active User
                </div>
                <ul class="nav-dropdown-items">
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('usership')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Active User' }"
                    >
                      <i class="nav-icon icon-list"></i> Active
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('usership')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Active User Buy Transaction' }"
                    >
                      <i class="nav-icon icon-list"></i> Buy Transaction
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('usership')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Active User Sell Transaction' }"
                    >
                      <i class="nav-icon icon-list"></i> Sell Transaction
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('usership')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Active User Gram Purchase' }"
                    >
                      <i class="nav-icon icon-list"></i> Gram Purchase
                    </router-link>
                  </li>
                  <li
                    class="nav-item"
                    @click="activeLink"
                    v-if="$can('usership')"
                  >
                    <router-link
                      class="nav-link"
                      :class="{ active: isActive }"
                      :to="{ name: 'Active User Money Spent' }"
                    >
                      <i class="nav-icon icon-list"></i> Money Spent
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item" @click="activeLink" v-if="$can('usership')">
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'New User' }"
                >
                  <i class="nav-icon fa fa-user-plus"></i> New User
                </router-link>
              </li>
              <li class="nav-item" @click="activeLink" v-if="$can('usership')">
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Silent User' }"
                >
                  <i class="nav-icon fa fa-user-secret"></i> Silent User
                </router-link>
              </li>
              <li class="nav-item" @click="activeLink" v-if="$can('usership')">
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Dead User' }"
                >
                  <i class="nav-icon fa fa-user-times"></i> Dead User
                </router-link>
              </li>
              <li
                class="nav-item mb-5"
                @click="activeLink"
                v-if="$can('usership')"
              >
                <router-link
                  class="nav-link"
                  :class="{ active: isActive }"
                  :to="{ name: 'Usership Setting' }"
                >
                  <i class="nav-icon fa fa-gears"></i> Usership Setting
                </router-link>
              </li>
              <!-- <li class="nav-item" @click="activeLink" v-if="$can('list_withdraw')">
                <router-link class="nav-link" :class="{active: isActive}" :to="{name: 'Withdraw'}">
                  <i class="nav-icon fa fa-upload"></i> Withdraw
                </router-link>
              </li> -->
            </ul>
          </VuePerfectScrollbar>
        </nav>
        <SidebarFooter />
        <!-- <SidebarMinimizer/> -->
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list" />
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <span class="ml-1"
          >&copy; 2020 Treasury Incorporation. All Rights Reserved</span
        >
      </div>
    </TheFooter>
  </div>
</template>

<script>
import nav from "@/_nav";
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  Footer as TheFooter,
  Breadcrumb
} from "@coreui/vue";
import { mixin as clickaway } from "vue-clickaway";
import { hideMobile } from "@coreui/vue/src/mixins/hideMobile";
import { togglePs } from "@coreui/vue/src/mixins/togglePs";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: "DefaultContainer",
  components: {
    AppHeader,
    AppSidebar,
    TheFooter,
    Breadcrumb,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    VuePerfectScrollbar
  },
  mixins: [clickaway, hideMobile, togglePs],
  data() {
    return {
      nav: nav.items,
      isActive: false
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        route => route.name || route.meta.label
      );
    },
    psSettings: () => {
      // ToDo: find better rtl fix
      return {
        maxScrollbarLength: 200,
        minScrollbarLength: 40,
        suppressScrollX:
          getComputedStyle(document.querySelector("html")).direction !== "rtl",
        wheelPropagation: false,
        interceptRailY: styles => ({ ...styles, height: 0 })
      };
    }
  },
  // created () {
  //   addEventListener('click', this.removeClick);
  // },
  methods: {
    scrollHandle() {
      // console.log(evt)
    },
    handleClick(e) {
      e.preventDefault();
      // document.getElementById("jewelry").classList.remove("open")
      // document.getElementById("dinar").classList.remove("open")
      // document.getElementById("acl").classList.remove("open")
      e.target.parentElement.classList.toggle("open");
    },
    // removeClick() {
    //   // document.getElementById("jewelry").classList.remove("open")
    //   // document.getElementById("dinar").classList.remove("open")
    //   // document.getElementById("acl").classList.remove("open")
    //   // document.getElementById("transaction").classList.remove("open")
    // },
    activeLink(event) {
      event.preventDefault();
      event.target.parentElement.classList.toggle(
        "router-link-exact-active open"
      );
    },
    logout() {
      this.$store
        .dispatch("destroyToken")
        .then(() => {
          this.$router.push({ name: "Login" });
          this.$toasted.success("Logout successful!");
        })
        .catch(error => {
          if (error.response) {
            this.errors.code = error.response.data.meta.code;
            this.errors.status = error.response.status;
            this.errors.headers = error.response.headers;
          }
        });
    }
  }
};
</script>
<style scoped lang="css">
.scroll-area {
  position: absolute;
  height: 100%;
  margin: auto;
}

.text-logo {
  color: #d1b332;
  font-weight: bold;
}
</style>
